import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { UpdateUser } from '../users/redux'
import { requestDeleteUser, requestUser } from '../users/services'
import { setError, setSuccess } from './redux'
import {
  requestGetUserVoteRights,
  requestManageUser,
  requestSetUserVoteRights,
  requestUserLogs,
  requestNewPasword,
  requestSendAccessExternal,
  requestLogsImpacted,
  requestLogsInitiators,
  requestDownloadUserLogs,
  requestSendAccess,
} from './services'
import { useUsers } from '../users/hooks'
import { toStringDate } from '../../utils/date-time.utils'

const getFullNameBirthDate = (user) => {
  if (!user) return null
  const firstNamePart = user.firstName
  const lastNamePart = user.lastName
  const birthDatePart = user.birthDate
    ? `(${toStringDate(user.birthDate, 'DD/MM/YYYY', null, false)})`
    : null
  return [firstNamePart, lastNamePart, birthDatePart].join(' ')
}

export const useUser = (userId: string | null) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.users.userById[userId || 0])

  const getUser = useCallback(() => dispatch(requestUser({ userId })), [userId, dispatch])

  const deleteUser = useCallback(() => {
    if (userId) return dispatch(requestDeleteUser({ userId }))
  }, [userId, dispatch])

  useEffect(() => {
    if (userId && !user) {
      getUser()
    }
  }, [getUser, user, userId])

  return { user, getUser, deleteUser }
}

export const useManageUser = (userId: string | null) => {
  const dispatch = useAppDispatch()

  const updateUser = useCallback(
    (userId, data) => dispatch(UpdateUser({ userId, data })),
    [dispatch]
  )

  const setUser = useCallback(
    (params) => dispatch(requestManageUser({ params, userId, callback: updateUser })),
    [dispatch, userId, updateUser]
  )
  const resetError = useCallback(() => dispatch(setError(null)), [dispatch])
  const resetSuccess = useCallback(() => dispatch(setSuccess(false)), [dispatch])

  const error = useAppSelector((state) => state.user.error)
  const success = useAppSelector((state) => state.user.success)
  const isLoading = useAppSelector((state) => state.user.isLoading)

  return { isLoading, error, success, resetSuccess, resetError, setUser }
}

export const useForgotPassword = () => {
  const dispatch = useAppDispatch()
  const getNewPassword = useCallback(
    (params, callback?: any) => dispatch(requestNewPasword({ params, callback })),
    [dispatch]
  )

  return getNewPassword
}

export const useSendPassword = (userId: string) => {
  const dispatch = useAppDispatch()
  const sendPassword = useCallback(
    (params, selectedTab, purpose, callback?: any) => {
      if (selectedTab === 'postal')
        dispatch(requestSendAccessExternal({ userId, params, selectedTab, purpose, callback }))
      else {
        dispatch(requestSendAccess({ userId, params, selectedTab, purpose, callback }))
      }
    },
    [userId, dispatch]
  )

  return sendPassword
}

export const useUserVotingLogs = (userId: string = 'all') => {
  const dispatch = useAppDispatch()
  const userLogs = useAppSelector((state) => state.users.userLogsById[userId])

  const getUserLogs = useCallback(
    (filters: any) => dispatch(requestUserLogs({ userId, filters })),
    [dispatch, userId]
  )

  const downloadUserLogs = useCallback(
    (filters) => dispatch(requestDownloadUserLogs({ userId, filters })),
    [dispatch, userId]
  )

  return { userLogs, getUserLogs, downloadUserLogs }
}

export const useUserVoteRights = (userId) => {
  const dispatch = useAppDispatch()

  const getUserVoteRights = useCallback(
    () => dispatch(requestGetUserVoteRights({ userId })),
    [dispatch, userId]
  )

  const setUserVoteRights = useCallback(
    (body) => dispatch(requestSetUserVoteRights({ userId, body })),
    [dispatch, userId]
  )

  useEffect(() => {
    getUserVoteRights()
  }, [getUserVoteRights])

  const userVoteRights = useAppSelector((state) => state.user.voteRights)

  return { getUserVoteRights, setUserVoteRights, userVoteRights }
}

export const useLogsData = () => {
  const dispatch = useAppDispatch()
  const { usersById, getUsers } = useUsers()

  const initiators = useAppSelector((state) => state.users.initiators)
  const impacted = useAppSelector((state) => state.users.impacted)

  const initiatorDatas = useMemo(
    () =>
      initiators.map((value) => {
        const label = usersById[value] ? getFullNameBirthDate(usersById[value]) : value
        return { value: value, label: label }
      }),
    [initiators, usersById]
  )

  const impactedDatas = useMemo(
    () =>
      impacted.map((value) => {
        const label = usersById[value] ? getFullNameBirthDate(usersById[value]) : value
        return { value: value, label: label }
      }),
    [impacted, usersById]
  )

  const getImpacted = useCallback(() => dispatch(requestLogsImpacted()), [dispatch])
  const getInitiators = useCallback(() => dispatch(requestLogsInitiators()), [dispatch])

  useEffect(() => {
    getImpacted()
    getInitiators()
  }, [getImpacted, getInitiators])

  useEffect(() => {
    getUsers('', { id: impacted.concat(initiators) }, true)
  }, [getUsers, impacted, initiators])

  return {
    initiatorDatas,
    impactedDatas,
  }
}
