import { createSlice } from '@reduxjs/toolkit'
import { deleteAllCookies } from '../../utils/security/cookies'
import { clearSessionStorage } from '../../utils/security/localStorage'

// Services
import { requestLoginStates } from './services'

// Utils
import { getTokenInfo } from './utils'
const defaultInfo: AuthState = {
  token: null,
  exp: null,
  needsPersonal: false,
  loggingInAdditionnal: false,
}

// Define a type for the slice state
export type AuthState = {
  token?: string | null
  role?: number
  exp?: number | null
  error?: boolean
  isLoading?: boolean
  needsPersonal: boolean
  loggingInAdditionnal: boolean
}

// Define the initial state using that type
const initialState: AuthState = {
  ...getTokenInfo(),
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearLogout: (state) => {
      state = defaultInfo
      deleteAllCookies()
      clearSessionStorage()
    },
  },
  extraReducers: (builder) => {
    requestLoginStates(builder)
  },
})

export const { clearLogout: authLogout } = auth.actions
