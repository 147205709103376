import { createSlice } from '@reduxjs/toolkit'
import { CIVILITY_DISPLAY } from '../../locales/constants'
import {
  requestLogsImpactedStates,
  requestLogsInitiatorsStates,
  requestUserLogsStates,
} from '../user/services'

// Services
import {
  requestDemoUsersStates,
  requestFiltersStates,
  requestProfileStates,
  requestRandomRoleStates,
  requestUsersStates,
  requestUserStates,
  requestSetUsersRoleStates,
  requestCandidatesStates,
  requestCommonStates,
} from './services'

// Utils

// Define a type for the slice state
export type UsersState = {
  userIds: Array<number | string>
  userById: { [key: number | string]: any }
  userLogsById: { [id: string]: any }
  totalUsers: number
  filters: any
  impacted: any[]
  initiators: any[]
}

// Define the initial state using that type
const initialState: UsersState = {
  userIds: [],
  userById: {},
  userLogsById: {},
  totalUsers: 0,
  filters: {},
  impacted: [],
  initiators: [],
}

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    UpdateUser: (state, { payload: { userId, data } }) => {
      state.userById[userId] = {
        ...state.userById[userId],
        ...data,
        civility: CIVILITY_DISPLAY[data.civility],
      }
    },
  },
  extraReducers: (builder) => {
    requestUserStates(builder)
    requestUsersStates(builder)
    requestProfileStates(builder)
    requestFiltersStates(builder)
    requestDemoUsersStates(builder)
    requestRandomRoleStates(builder)
    requestSetUsersRoleStates(builder)
    requestCandidatesStates(builder)
    requestCommonStates(builder)
    requestUserLogsStates(builder)
    requestLogsImpactedStates(builder)
    requestLogsInitiatorsStates(builder)
  },
})

export const { UpdateUser } = users.actions
