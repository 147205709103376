// Hooks
import { useVoteInfo } from '../vote/hooks'
import { useIsSealed } from '../sealing/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'

// Utils
import { getStatus, STATUS_ENUM } from '../../utils/lib/status'
import { getDistance } from '../../utils/lib/timer'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { UpdateClosingTime } from '../configuration/redux'
import { usePlatformStatus } from '../appConfig/hooks'
import { useNowTimer } from '../time/hooks'

export const useCurrentVoteStatus = () => {
  const isSealed = useIsSealed()
  const voteInfo = useVoteInfo()
  //const { today } = useNowTimer()
  const today = useAppSelector((state) => state.time.serverTime.currentTime)
  // States
  const [voteStarted, setVoteStarted] = useState(false)
  const [voteEnded, setVoteEnded] = useState(false)
  const [voteOngoing, setVoteOngoing] = useState(false)

  useEffect(() => {
    if (!isSealed && !voteEnded) {
      setVoteStarted(false)
      setVoteOngoing(false)
      setVoteEnded(false)
    } else if (voteInfo.closeMixDate && voteInfo.openMixDate) {
      const openAt = Number(new Date(voteInfo.openMixDate))
      const closeAtDate = new Date(voteInfo.closeMixDate)
      closeAtDate.setMinutes(closeAtDate.getMinutes() + voteInfo.additionalVoteTime)
      const closeAt = Number(closeAtDate)
      if ((openAt && today >= openAt) !== voteStarted) {
        setVoteStarted(!voteStarted)
      }
      if ((closeAt && openAt && today >= openAt && today <= closeAt) !== voteOngoing) {
        setVoteOngoing(!voteOngoing)
      }
      if ((closeAt && today > closeAt) !== voteEnded) {
        setVoteEnded(!voteEnded)
      }
    } else {
      setVoteStarted(false)
      setVoteOngoing(false)
      setVoteEnded(false)
    }
  }, [
    voteInfo.closeMixDate,
    voteInfo.openMixDate,
    today,
    voteInfo.additionalVoteTime,
    voteStarted,
    voteOngoing,
    voteEnded,
  ])

  return { voteStarted, voteEnded, voteOngoing }
}

// For VoteInfo data
export const useVoteInfoStatus = (addTimeLeft: boolean = false) => {
  const dispatch = useAppDispatch()
  const { today } = useNowTimer()
  const { PLATFORM_SEALED } = usePlatformStatus()
  const { openMixDate, closingTime } = useVoteInfo()

  const { openAt, closesAt } = useMemo(
    () => ({
      openAt: openMixDate ? new Date(openMixDate) : null,
      closesAt: closingTime ? new Date(closingTime) : null,
    }),
    [closingTime, openMixDate]
  )

  // States
  const [status, setStatus] = useState(getStatus(today, openAt, closesAt, PLATFORM_SEALED))
  const [timeLeft, setTimeLeft] = useState(
    closesAt && PLATFORM_SEALED ? getDistance(today, Number(closesAt)) : null
  )

  const setClosingTime = useCallback(() => dispatch(UpdateClosingTime()), [dispatch])

  useEffect(() => {
    setClosingTime()
    let newStatus = getStatus(today, openAt, closesAt, PLATFORM_SEALED)
    if (newStatus !== status) setStatus(newStatus)
    if (addTimeLeft && closesAt && PLATFORM_SEALED) {
      setTimeLeft(getDistance(today, Number(closesAt)))
    }
  }, [addTimeLeft, closesAt, openAt, status, today, PLATFORM_SEALED, setClosingTime])

  return { status, timeLeft }
}

// For Me Polls Status
export const usePollStatus = (
  openingDate?: string,
  closingDate?: string,
  isProgressive?: boolean
) => {
  const { today } = useNowTimer()
  const isSealed = useIsSealed()
  const { voteOngoing, voteEnded } = useCurrentVoteStatus()

  const [status, setStatus] = useState<STATUS_ENUM | null>(null)

  useEffect(() => {
    let newStatus: any = null

    if (isProgressive) {
      newStatus = isSealed
        ? getStatus(
            today,
            openingDate ? new Date(openingDate) : null,
            closingDate ? new Date(closingDate) : null,
            isSealed
          )
        : null
    } else {
      if (voteEnded) newStatus = STATUS_ENUM.closed
      else if (voteOngoing) newStatus = STATUS_ENUM.open
      else newStatus = STATUS_ENUM.starting
    }
    if (newStatus !== status) {
      setStatus(newStatus)
    }
  }, [closingDate, isProgressive, isSealed, openingDate, status, voteEnded, voteOngoing, today])

  return status
}
