import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import {
  requestRoles,
  requestPrivileges,
  requestSetPrivileges,
  requestAdminPrivileges,
  requestResetRolePrivileges,
  requestSetRolePrivileges,
} from './services'
import { setTmpPrivileges, requestSetCollegeIds, requestSetUserIds } from './redux'

export const useRoles = () => {
  const dispatch = useAppDispatch()
  const roles = useAppSelector((state) => state.roles.roles)

  const getRoles = useCallback(() => dispatch(requestRoles()), [dispatch])
  return { roles, getRoles }
}

export const useRolesPrivileges = () => {
  const dispatch = useAppDispatch()
  const privileges = useAppSelector((state) => state.roles.privileges)

  const tmpPrivileges = useAppSelector((state) => state.roles.tmpPrivileges)
  const userIds = useAppSelector((state) => state.roles.userIds)
  const userById = useAppSelector((state) => state.users.userById)

  const setPrivileges = useCallback(
    () => dispatch(requestSetPrivileges({ userIds, privileges: tmpPrivileges })),
    [dispatch, userIds, tmpPrivileges]
  )
  const setTmpPrivilegess = useCallback(
    (privileges) => dispatch(setTmpPrivileges({ privileges })),
    [dispatch]
  )

  const getPrivileges = useCallback(
    (userIds) => dispatch(requestPrivileges({ userIds })),
    [dispatch]
  )

  const userRoles = userIds.reduce((acc, userId) => {
    if (!acc.includes(userById[userId].role)) {
      acc.push(userById[userId].role)
    }
    return acc
  }, [])

  return { privileges, setPrivileges, getPrivileges, setTmpPrivilegess, userRoles }
}

export const useCollegeIds = (collegeIds) => {
  const dispatch = useAppDispatch()
  const collegeIdsUpdated = useAppSelector((state) => state.roles.collegeIds)

  const setCollegeIds = useCallback(
    (toUpdate) =>
      dispatch(
        requestSetCollegeIds({
          collegeIdsBase: collegeIdsUpdated || collegeIds,
          toUpdate,
        })
      ),
    [dispatch, collegeIdsUpdated, collegeIds]
  )

  return { setCollegeIds }
}

export const useResetRole = (collegeIds) => {
  const dispatch = useAppDispatch()
  const collegeIdsUpdated = useAppSelector((state) => state.roles.collegeIds)

  const resetRolePrivileges = useCallback(
    (role) => {
      dispatch(
        requestResetRolePrivileges({
          role,
          collegeIds: collegeIdsUpdated || collegeIds,
        })
      )
    },
    [dispatch, collegeIdsUpdated, collegeIds]
  )

  return { resetRolePrivileges }
}

export const useSetUserRole = () => {
  const dispatch = useAppDispatch()

  const setUserIds = useCallback(
    (userIds) =>
      dispatch(
        requestSetUserIds({
          userIds,
        })
      ),
    [dispatch]
  )
  return { setUserIds }
}

export const useAdminPrivileges = () => {
  const dispatch = useAppDispatch()
  const adminPrivileges = useAppSelector((state) => state.roles.adminPrivileges)
  const tmpPrivileges = useAppSelector((state) => state.roles.tmpPrivileges)

  const collegeIds = useAppSelector((state) => state.roles.collegeIds)

  const enabledCollegeIds = useMemo(
    () =>
      collegeIds &&
      Object.entries(collegeIds)
        .filter(([_id, status]) => status === 'ENABLED')
        .map(([id]) => id),
    [collegeIds]
  )

  const getAdminPrivileges = useCallback(
    (role) =>
      dispatch(
        requestAdminPrivileges({ role, establishmentsIds: null, collegeIds: enabledCollegeIds })
      ),
    [dispatch, enabledCollegeIds]
  )

  const setAdminPrivileges = useCallback(
    (role) => {
      dispatch(
        requestSetRolePrivileges({ role, collegeIds: enabledCollegeIds, privileges: tmpPrivileges })
      )
    },
    [dispatch, tmpPrivileges, enabledCollegeIds]
  )
  return { adminPrivileges, getAdminPrivileges, setAdminPrivileges }
}
