import { useCallback, useMemo } from 'react'
import { requestLogin as reqLogin, requestResetPassword } from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { CORRESPONDANCE_ROLES } from '../roles/constants'

export const useAuth = () => {
  const dispatch = useAppDispatch()

  const token = useAppSelector((state) => state.auth.token)
  const error = useAppSelector((state) => state.auth.error)
  const errorBody = useAppSelector((state) => state.auth.errorBody)
  const needsPersonal = useAppSelector((state) => state.auth.needsPersonal)
  const loggingInAdditionnal = useAppSelector((state) => state.auth.loggingInAdditionnal)
  const isLoading = useAppSelector((state) => state.auth.isLoading)
  const welcomeMessage = useAppSelector((state) => state.auth.welcomeMessage)

  // Actions
  const requestLogin = useCallback(
    (username: string, password: string, personalInformation?: string) =>
      dispatch(reqLogin({ username, password, personalInformation })),
    [dispatch]
  )

  return {
    error,
    errorBody,
    token,
    needsPersonal,
    loggingInAdditionnal,
    isLoading,
    requestLogin,
    welcomeMessage,
  }
}

export const usePrivileges = () => {
  const privileges = useAppSelector((state) => state.auth.privileges)

  const myPrivileges = useMemo(
    () =>
      Object.entries(privileges || {}).reduce(
        (acc: any, [key, value]: any) => ({
          ...acc,
          [key]: value.scope || value.isEnabled,
        }),
        { loaded: !!privileges }
      ),
    [privileges]
  )

  return { ...myPrivileges }
}

export const useAuthCheck = () => {
  const isLogged = useAppSelector((state) => !!state.auth.token)
  const isAdmin = useAppSelector(
    (state) =>
      state.auth.role === CORRESPONDANCE_ROLES.admin ||
      state.auth.role === CORRESPONDANCE_ROLES.supervisor
  )
  const meRole = useAppSelector((state) => state.auth.role)
  const userId = useAppSelector((state) => state.profile.userId)
  const cellMemberId = useAppSelector((state) => state.auth.cellMemberId)

  return { cellMemberId, isLogged, isAdmin, meRole, userId }
}

export const useAuthPrivileges = () => {
  const authPrivileges = useAppSelector((state) => state.auth.privileges)

  return { authPrivileges }
}

export const useIsProxied = (pollId?: string) => {
  const isProxied = useAppSelector((state) => !!state.auth.proxiedPollIds?.includes(pollId))
  return isProxied
}

export const useProxiedRepresentative = (pollId?: string) => {
  const proxiedRepresentative = useAppSelector((state) =>
    state.auth.proxiedRepresentative.find((item) => item.pollId === pollId)
  )

  return proxiedRepresentative
}

export const useResetPasswordWithToken = (token) => {
  const dispatch = useAppDispatch()

  const resetPassword = useCallback(
    (params: any, callback?: any) => dispatch(requestResetPassword({ params, token, callback })),
    [dispatch, token]
  )
  return resetPassword
}
