// Hooks
import { useCallback, useEffect, useMemo, useState } from 'react'
import useLocalize from '../../../../../locales/hook'
import { useContactInformations } from '../../../../../redux/sealing/hooks'
//import { useProfile } from '../../../../../redux/profile/hooks'

// Components
import { IonModal } from '@ionic/react'
import Icon from '../../../../../atoms/Icon/Icon'
import Button from '../../../../../atoms/Button/Button'
import TextInput from '../../../../../atoms/TextInput/TextInput'
import PhoneInput from '../../../../../atoms/PhoneInput/PhoneInput'

// Styles & Utils
import './CellMemberContactInformationModal.scss'
import IconTypes from '../../../../../utils/types/IconTypes'
import isEmailValid from '../../../../../utils/booleans/isEmailValid'
import {
  setShouldSendInformationsEmailError,
  setShouldSendInformationsPhoneError,
} from '../../../../../redux/sealing/redux'

const EMPTY = 'empty'
const ERROR = 'error'
const VALID = 'valid'

const DEFAULT_MANDATORY = {
  email: EMPTY,
  phone: EMPTY,
  all: VALID,
}

const CellMemberContactInformationModal: React.FC = () => {
  // Hooks
  const t = useLocalize()
  const {
    sendContactInformations,
    shouldSendInformations,
    shouldSendInformationsPhoneError,
    setShouldSendInformations,
    shouldSendInformationsEmailError,
    pEmail,
    pPhoneNumber,
    pPrefix,
  } = useContactInformations()

  // States
  const [personalPhonePrefix, setPrefix] = useState(pPrefix || '')
  const [personalEmail, setEmail] = useState(pEmail || '')
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [personalPhoneNumber, setPhoneNumber] = useState(pPhoneNumber || '')
  const [emailMessageError, setEmailMessageError] = useState('')
  const [personalPhoneNumberTmp, setPhoneNumberTmp] = useState(pPhoneNumber || '')

  const [mandatory, setMandatory] = useState({
    ...DEFAULT_MANDATORY,
    phone: pPhoneNumber ? VALID : EMPTY,
    email: pEmail ? VALID : EMPTY,
  })
  //added by southPigall
  useEffect(() => {
    setPhoneNumber(pPhoneNumber)
    setEmail(pEmail)
  }, [pPhoneNumber, pEmail])

  useEffect(() => {
    if (!shouldSendInformations) {
      setMandatory(DEFAULT_MANDATORY)
    }
  }, [shouldSendInformations])

  useEffect(() => {
    if (!shouldSendInformationsEmailError) {
    } else {
      setEmailMessageError(t('this_email_address_is_already_in_use'))
      setEmailError(true)
    }
  }, [shouldSendInformationsEmailError])

  useEffect(() => {
    if (!shouldSendInformationsPhoneError) {
    } else {
      setMandatory((state) => {
        state.phone = ERROR
        return { ...state }
      })
    }
  }, [shouldSendInformationsPhoneError])

  // Handlers
  const handleValidate = useCallback(() => {
    if (personalPhoneNumber) {
      if (personalPhoneNumber.length < 9) {
        setPhoneError(true)
        return
      }
    }

    if (personalEmail) {
      if (!isEmailValid(personalEmail)) {
        setEmailError(true)
        return
      }
    }

    if (!personalEmail && !personalPhoneNumber) {
      setEmailError(true)
      setPhoneError(true)
      return
    }

    setEmailMessageError('')

    sendContactInformations({ personalPhonePrefix, personalPhoneNumber, personalEmail })
    setPhoneNumberTmp('')
    setPhoneNumber('')
    setPhoneError(false)
    setEmailError(false)
  }, [mandatory, sendContactInformations, personalPhonePrefix, personalPhoneNumber, personalEmail])

  const handleCloseModal = useCallback(() => {
    setShouldSendInformations(false)
  }, [])

  const handleOnBlurEmail = useCallback(() => {
    setMandatory((state) => {
      if (!personalEmail) {
        state.email = EMPTY
      } else if (isEmailValid(personalEmail)) {
        state.email = VALID
        state.all = VALID
      } else {
        state.email = ERROR
      }
      return { ...state }
    })
  }, [setMandatory, personalEmail])

  const handleOnChangePhoneNumber = useCallback((prefix, phoneNumber, isEmpty) => {
    setPrefix(prefix)
    setPhoneNumberTmp(phoneNumber)
    if (phoneNumber) {
      setPhoneNumber(phoneNumber)
    }
  }, [])

  const handleOnBlurPhoneNumber = useCallback(
    (_ev, _key, length) => {
      setMandatory((state) => {
        if (!length) {
          state.phone = EMPTY
        } else if (personalPhoneNumberTmp) {
          state.phone = VALID
          state.all = VALID
        } else state.phone = ERROR
        return { ...state }
      })
    },
    [setMandatory, personalPhoneNumberTmp]
  )

  return (
    <IonModal
      className='CellMemberContactInformationModal'
      isOpen={
        shouldSendInformationsEmailError !== true && shouldSendInformationsPhoneError !== true
          ? shouldSendInformations
          : true
      }
      onWillDismiss={handleCloseModal}
    >
      <Icon className='close' icon={IconTypes.X} onClick={handleCloseModal} />
      <div className='title'>{t('modal_contact_information_title')}</div>
      <div className='box'>
        <div className='description'>{t('modal_contact_information_description')}</div>
        <PhoneInput
          label={t('phoneNumber')}
          className='textInputBox'
          valueKey='phone'
          error={shouldSendInformationsPhoneError || phoneError}
          initValue={personalPhoneNumber || ''}
          onChange={handleOnChangePhoneNumber}
          onBlur={handleOnBlurPhoneNumber}
        />
        {shouldSendInformationsPhoneError ? (
          <div className='phoneInputBox isError'>
            <div className='label'>{t('phone_number_already_existing')}</div>
          </div>
        ) : null}

        {phoneError ? (
          <div className='phoneInputBox isError'>
            <div className='label'>{t('check_phone_number')}</div>
          </div>
        ) : null}

        <TextInput
          type='email'
          label={t('email')}
          className='textInputBox'
          valueKey='email'
          error={emailError}
          errorMessage={emailMessageError}
          prioValue={personalEmail}
          maxLength={80}
          onChange={setEmail}
          onBlur={handleOnBlurEmail}
          placeholder={t('email_assistance_placeholder')}
        />
        <div className='buttonBox'>
          <Button fill label={t('validate')} onClick={handleValidate} />
        </div>
      </div>
    </IonModal>
  )
}

export default CellMemberContactInformationModal
