import { useCallback, useEffect /*, useMemo*/ } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { requestEstablishments } from './services'

export const useEstablishments = () => {
  const dispatch = useAppDispatch()
  const establishmentIds = useAppSelector((state) => state.establishments.establishmentIds)
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')
  const isVoteDataImported = useAppSelector((state) => state.appConfig.status?.VOTE_DATA_IMPORTED)
  const establishmentsById = useAppSelector((state) => state.establishments.establishmentById)

  // const getEstablishments = useCallback(() => {
  //   if (isVoteDataImported) {
  //     dispatch(requestEstablishments({ type: isCSE ? 'cse' : 'lis' }))
  //   }
  // }, [dispatch, isCSE, isVoteDataImported])

  const getEstablishments = useCallback(() => {
    if (isVoteDataImported && !establishmentIds.length) {
      dispatch(requestEstablishments({ type: isCSE ? 'cse' : 'lis' }))
    }
  }, [dispatch, isCSE, isVoteDataImported, establishmentIds.length])

  useEffect(() => {
    if (isVoteDataImported) {
      getEstablishments()
    }
  }, [getEstablishments, isVoteDataImported])

  return { establishmentsById, establishmentIds, getEstablishments }
}
