import { useCallback, useEffect, useRef, useState } from 'react'
import IntlTelInput from 'react-intl-tel-input'

import 'react-intl-tel-input/dist/main.css'
import './PhoneInput.scss'

interface Props {
  className?: string
  label?: string
  onChange: (code: string, phone: string | null, empty: boolean) => void
  disabled?: boolean
  placeholder?: string
  initValue?: string
  initFlag?: string
  error?: boolean
  valueKey?: string
  onBlur?: (ev: any, key?: string, length?: number) => void
  onFocus?: (ev: any, key?: string) => void
}

const PhoneInput: React.FC<Props> = ({
  className = '',
  placeholder,
  onChange,
  label,
  disabled,
  initValue = '',
  initFlag = '',
  valueKey = 'phoneNumber',
  error,
  onBlur,
  onFocus,
}) => {
  const [prefix, setPrefix] = useState('')
  const [value, setValue] = useState(initValue)

  useEffect(() => {
    setPrefix('')
    setValue(initValue)
  }, [initValue])
  const pCountries: any = useRef(['fr']).current

  const handleFocus = useCallback((ev) => onFocus?.(ev, valueKey), [onFocus, valueKey])
  const handleBlur = useCallback(
    (ev) => onBlur?.(ev, valueKey, value?.length),
    [onBlur, value?.length, valueKey]
  )

  const handleChangeFlag = useCallback((_, info) => {
    setPrefix(info?.iso2)
  }, [])

  const handleChange = useCallback(
    (validated, val, info) => {
      const nValue = new RegExp(/^[0-9]*$/gm).exec(val)
      if (nValue && val !== value) {
        setValue(val.replace(/ /gm, ''))
        if (validated) {
          onChange(`+${info?.dialCode}`, val.replace(/ /gm, ''), false)
        } else {
          onChange(`+${info?.dialCode}`, null, !val.replace(/ /gm, '').length)
        }
      } else if (val !== value) {
        setValue(val.replace(/ /gm, ''))
        onChange(`+${info?.dialCode}`, val.replace(/ /gm, ''), false)
      }
    },
    [onChange, value]
  )

  return (
    <div className={`phoneInputBox ${className} ${error ? 'isError' : ''}`}>
      {!!label?.length && <div className='label'>{label}</div>}
      <IntlTelInput
        separateDialCode
        value={value}
        disabled={disabled}
        formatOnInit
        format
        containerClassName='intl-tel-input'
        inputClassName='form-control'
        preferredCountries={pCountries}
        placeholder={placeholder}
        defaultCountry={prefix}
        onSelectFlag={handleChangeFlag}
        onPhoneNumberBlur={handleBlur}
        onPhoneNumberFocus={handleFocus}
        onPhoneNumberChange={handleChange}
      />
    </div>
  )
}

export default PhoneInput
