import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  requestCellMembers,
  requestChangeCellMembers,
  requestConfirmPassword,
  requestGenerateKeys,
  requestGetSealing,
  requestMeSealing,
  requestMeUnseal,
  requestSendContactInformations,
  requestSetSealing,
  requestUsers,
  requestSetUnsealing,
  requestRandomCellMember,
} from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'
import {
  setShouldSealStatus,
  setShouldSendInformations,
  setShouldSendInformationsEmailError,
  setShouldSendInformationsPhoneError,
  setShouldShowPassword,
  setShouldUnsealStatus,
  setGeneratingPercentage,
  setClearKeys,
  setOverrideCellMemberUsers,
} from './redux'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'
import { useIonRouter } from '@ionic/react'
import ROUTES from '../../routes/constants/routes'
import { useCurrentVoteStatus } from '../app/statusHook'
import { useNowTimer } from '../time/hooks'
import { requestStatus } from '../appConfig/services'
import { requestAnimators } from '../animators/services'

// Scrutins
export const useSealing = () => {
  const dispatch = useAppDispatch()
  const isSealed = useAppSelector((state) => state.sealing.isSealed)

  const getSealingStatus = useCallback(() => dispatch(requestGetSealing()), [dispatch])

  const setSealing = useCallback(() => {
    dispatch(requestSetSealing(isSealed))
  }, [dispatch, isSealed])

  const setUnsealing = useCallback(() => {
    dispatch(requestSetUnsealing(isSealed))
  }, [dispatch, isSealed])

  return { getSealingStatus, setSealing, isSealed, setUnsealing }
}

export const useGenerateKeys = () => {
  const dispatch = useAppDispatch()
  const generateKeys = useCallback(() => dispatch(requestGenerateKeys()), [dispatch])

  const errorKeys = useAppSelector((state) => state.sealing.errorKeys)
  const users = useAppSelector((state) => Object.values(state.sealing.cellMembersById || {}))

  const canGenerateKeys = useMemo(
    () =>
      users.length ===
      users.filter(
        ({ personalEmail, personalPhoneNumber }: any) =>
          !!personalEmail?.length || !!personalPhoneNumber?.length
      ).length,
    [users]
  )

  return { generateKeys, errorKeys, canGenerateKeys }
}

export const useIsGeneratingKeys = () => {
  const dispatch = useAppDispatch()
  const isGenerating = useAppSelector((state) => state.sealing.isGenerating)
  const generatingPercentage = useAppSelector((state) => state.sealing.isGeneratingPercentage)

  const timer = useRef<NodeJS.Timer | null>()

  const setPercentage = useCallback((value) => dispatch(setGeneratingPercentage(value)), [dispatch])
  const statusHandler = useCallback(() => {
    timer.current = setInterval(async () => {
      const rStatus: any = await getAxios('/encryption/generation-status', getTokenHeaders())

      if (rStatus.error) {
        setPercentage(0)
        return timer.current && clearInterval(timer.current)
      }
      const { nbPollsTreated, totalPollsToTreat } = rStatus.data

      setPercentage(Math.ceil((nbPollsTreated * 100) / totalPollsToTreat))
    }, 250)

    return () => timer.current && clearInterval(timer.current)
  }, [setPercentage])

  // Status Handler
  useEffect(() => {
    timer.current && clearInterval(timer.current)
    if (!!isGenerating) {
      setPercentage(0)
      statusHandler()
    } else {
      setPercentage(null)
    }
  }, [isGenerating, setPercentage, statusHandler])

  return { isGenerating, generatingPercentage }
}

export const useIsSealed = () => {
  const isSealed = useAppSelector((state) => state.sealing.isSealed)
  return isSealed
}

export const useCellingMembers = () => {
  const cellMembersIds = useAppSelector((state) => state.sealing.cellMembersIds)
  return cellMembersIds
}

export const useManageCellMembers = () => {
  const dispatch = useAppDispatch()

  const cellMemberUserIds = useAppSelector((state) =>
    Object.values(state.sealing.cellMembersById || {}).map(({ userId }: any) => userId)
  )

  const getUsers = useCallback(
    (search) => {
      dispatch(requestUsers({ search }))
    },

    [dispatch]
  )
  const setCellMember = useCallback(
    (userId, cellId) => dispatch(requestChangeCellMembers({ userId, cellId })),
    [dispatch]
  )

  const userIds = useAppSelector((state) => state.sealing.potentialUserIds)
  const users = useAppSelector((state) => state.sealing.potentialUserCell)

  return {
    getUsers,
    userIds,
    users,
    cellMemberUserIds,
    setCellMember,
  }
}

export const useCellingValidated = () => {
  const { voteEnded } = useCurrentVoteStatus()
  const canValidateSeal = useAppSelector((state) => state.appConfig.status.PLATFORM_SEAL_READY)
  const canValidateUnseal = useAppSelector((state) => state.appConfig.status.PLATFORM_UNSEAL_READY)
  const unsealConfirmed = useAppSelector((state) => voteEnded && state.sealing.isSealed)
  const isVoteDataImported = useAppSelector((state) => state.appConfig.status?.VOTE_DATA_IMPORTED)
  const type = useAppSelector((state) => state.configuration?.type)
  const progressiveVoteStatus = useAppSelector(
    (state) => state.appConfig.status.PROGRESSIVE_VOTE_STATUS
  )
  // Permet d'identifier si il contient vote progressif afin de gerer le descellement et l'insertion des votes mixtes aprés que l'animateurs ait finit les votes progressifs
  const openResolution = type === 'Other' && progressiveVoteStatus
  return {
    canValidateSeal,
    canValidateUnseal,
    unsealConfirmed,
    isVoteDataImported,
    openResolution,
  }
}

export const useCellingMember = (id: number) => {
  const cellMember = useAppSelector((state) => state.sealing.cellMembersById[id] || {})
  const dispatch = useAppDispatch()
  const deleteCellMember = useCallback(
    () => dispatch(requestChangeCellMembers({ cellId: id })),
    [dispatch, id]
  )

  return { ...cellMember, deleteCellMember }
}

export const useGetCellingStatus = () => {
  const dispatch = useAppDispatch()
  const getCellingStatus = useCallback(() => {
    dispatch(requestCellMembers({}))
    dispatch(requestStatus())
  }, [dispatch])

  return { getCellingStatus }
}

export const useGetCellingMembersAndAnims = () => {
  const dispatch = useAppDispatch()

  const getCellingAndAnims = useCallback(() => {
    dispatch(requestCellMembers({}))
    dispatch(requestAnimators())
  }, [dispatch])

  return { getCellingAndAnims }
}

export const useTmpKeys = () => {
  const keys = useAppSelector((state) => state.sealing.keys)

  const dispatch = useAppDispatch()
  const clearKeys = useCallback(() => dispatch(setClearKeys()), [dispatch])

  return { keys, clearKeys }
}

export const useSeal = () => {
  const dispatch = useAppDispatch()

  const {
    routeInfo: { pathname },
  } = useIonRouter()

  const cellMemberId = useAppSelector((state) => state.auth.cellMemberId)
  const hasConfirm = useAppSelector((state) => state.sealing.me?.hasConfirmedPassword)
  const error = useAppSelector<boolean | undefined>((state) => state.sealing.me?.error)
  const encryptionKeysGenerated = useAppSelector(
    (state) => state.appConfig?.status?.ENCRYPTION_KEYS_GENERATED
  )

  const waitingSeal = useAppSelector((state) => state.sealing.waitingSeal)
  const isSealed = useAppSelector((state) => state.sealing.isSealed)
  const shouldSendInformationsFirst = useAppSelector(
    (state) =>
      !state.sealing.me?.personalPhoneNumber?.length && !state.sealing.me?.personalEmail?.length
  )
  const shouldSeal = useAppSelector((state) => state.sealing.shouldSeal)
  const showModal = useAppSelector((state) => state.sealing.shouldShowPassword)
  const setShouldSeal = useCallback((value) => dispatch(setShouldSealStatus(value)), [dispatch])
  const setShowPassword = useCallback((value) => dispatch(setShouldShowPassword(value)), [dispatch])

  const validatePassword = useCallback(
    (password) => {
      dispatch(requestConfirmPassword({ password }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (cellMemberId && encryptionKeysGenerated) {
      dispatch(requestMeSealing())
    }
  }, [cellMemberId, encryptionKeysGenerated, dispatch])

  // This timer is to wait for all this info before setting the modal
  const timer = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      const value =
        pathname !== ROUTES.DEMO_LOGIN &&
        !shouldSendInformationsFirst &&
        cellMemberId &&
        typeof hasConfirm === 'boolean' &&
        !hasConfirm &&
        encryptionKeysGenerated &&
        !isSealed

      setShouldSeal(value)
    }, 500)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [
    cellMemberId,
    hasConfirm,
    encryptionKeysGenerated,
    shouldSendInformationsFirst,
    setShouldSeal,
    isSealed,
    pathname,
  ])

  return {
    error,
    showModal,
    shouldSeal,
    waitingSeal,
    setShouldSeal,
    setShowPassword,
    validatePassword,
  }
}

export const useUnseal = () => {
  const dispatch = useAppDispatch()

  const {
    routeInfo: { pathname },
  } = useIonRouter()

  const { today } = useNowTimer()
  const error = useAppSelector((state) => state.sealing.me?.error)
  const cellMemberId = useAppSelector((state) => state.auth.cellMemberId)
  const hasUnseal = useAppSelector((state) => state.sealing.me?.hasUnlockedPlatform)
  const waitingUnseal = useAppSelector((state) => state.sealing.waitingUnseal)

  const config = useAppSelector((state) => state.configuration.config)
  const closingTime = config?.closingTime

  const COUNT_DONE = useAppSelector((state) => state.appConfig.status?.COUNT_DONE)

  const isSealed = useAppSelector((state) => state.sealing.isSealed)
  const shouldSendInformationsFirst = useAppSelector(
    (state) =>
      !state.sealing.me?.personalPhoneNumber?.length && !state.sealing.me?.personalEmail?.length
  )
  const shouldUnseal = useAppSelector((state) => state.sealing.shouldUnseal)

  const showModal = useAppSelector((state) => state.sealing.shouldShowPassword)

  const setShouldUnseal = useCallback((value) => dispatch(setShouldUnsealStatus(value)), [dispatch])
  const setShowPassword = useCallback((value) => dispatch(setShouldShowPassword(value)), [dispatch])

  const validatePassword = useCallback(
    (password) => {
      dispatch(requestMeUnseal({ password }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (cellMemberId) {
      dispatch(requestMeSealing())
    }
  }, [dispatch, cellMemberId])

  // This timer is to wait for all this info before setting the modal
  const timer = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      const value =
        pathname !== ROUTES.DEMO_LOGIN &&
        !shouldSendInformationsFirst &&
        cellMemberId &&
        typeof hasUnseal === 'boolean' &&
        !hasUnseal &&
        today > Number(new Date(closingTime)) &&
        isSealed &&
        !COUNT_DONE
      setShouldUnseal(value)
    }, 500)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [
    cellMemberId,
    hasUnseal,
    closingTime,
    setShouldUnseal,
    shouldSendInformationsFirst,
    isSealed,
    pathname,
    today,
    COUNT_DONE,
  ])

  return {
    error,
    showModal,
    shouldUnseal,
    waitingUnseal,
    setShouldUnseal,
    setShowPassword,
    validatePassword,
  }
}

export const useContactInformations = () => {
  const dispatch = useAppDispatch()

  const {
    routeInfo: { pathname },
  } = useIonRouter()
  const loaded = useAppSelector((state) => !!state.sealing.me)
  const cellMemberId = useAppSelector((state) => state.auth.cellMemberId)
  const personalPhoneNumber = useAppSelector((state) => state.sealing.me?.personalPhoneNumber)
  const personalPhonePrefix = useAppSelector((state) => state.sealing.me?.personalPhonePrefix)
  const personalEmail = useAppSelector((state) => state.sealing.me?.personalEmail)

  const shouldSendInformations = useAppSelector((state) => state.sealing.shouldSendInformations)

  const shouldSendInformationsEmailError = useAppSelector(
    (state) => state.sealing.shouldSendInformationsEmailError
  )
  const shouldSendInformationsPhoneError = useAppSelector(
    (state) => state.sealing.shouldSendInformationsPhoneError
  )

  const setSendInformations = useCallback(
    (value: boolean) => dispatch(setShouldSendInformations(value)),
    [dispatch]
  )
  const setSendInformationsEmailError = useCallback(
    (value: boolean) => dispatch(setShouldSendInformationsEmailError(value)),
    [dispatch]
  )

  const setSendInformationsPhoneError = useCallback(
    (value: boolean) => dispatch(setShouldSendInformationsPhoneError(value)),
    [dispatch]
  )
  const sendContactInformations = useCallback(
    (contactInformations: {
      personalPhonePrefix?: string
      personalPhoneNumber?: string
      personalEmail?: string
    }) => {
      setSendInformations(false)
      setSendInformationsEmailError(false)
      dispatch(requestSendContactInformations(contactInformations))
    },
    [dispatch, setSendInformations, setSendInformationsEmailError]
  )

  useEffect(() => {
    if (cellMemberId) {
      dispatch(requestMeSealing())
    }
  }, [dispatch, cellMemberId])

  // This timer is to wait for all this info before setting the modal
  const timer = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setSendInformations(
        pathname !== ROUTES.DEMO_LOGIN &&
          loaded &&
          !!(!personalEmail?.length && !personalPhoneNumber?.length && cellMemberId)
      )
    }, 500)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [setSendInformations, loaded, personalEmail, personalPhoneNumber, cellMemberId, pathname])

  return {
    isEmpty: !personalPhoneNumber?.length && !personalEmail?.length,
    isCellMember: !!cellMemberId,
    sendContactInformations,
    shouldSendInformations,
    shouldSendInformationsEmailError,
    shouldSendInformationsPhoneError,
    pPhoneNumber: personalPhoneNumber,
    pEmail: personalEmail,
    pPrefix: personalPhonePrefix,
    setShouldSendInformations: setSendInformations,
    setShouldSendInformationsEmailError: setSendInformationsEmailError,
    setShouldSendInformationsPhoneError: setSendInformationsPhoneError,
  }
}

// Get and use a selection that can be overrided by a randomizer
//   (aka useRandomRole or getAllRandom)
export const useRandomCellMemberOverride = () => {
  const dispatch = useAppDispatch()

  const overrideCellMemberUsers = useAppSelector(
    (state) => state.sealing.userIdsCellMemberSelection
  )

  const getAllRandom = useCallback(
    (filters) => {
      dispatch(requestRandomCellMember({ filters, clear: true }))
    },
    [dispatch]
  )

  const setUsersCellMemberOverride = useCallback(
    (users) => dispatch(setOverrideCellMemberUsers(users)),
    [dispatch]
  )

  return { overrideCellMemberUsers, setUsersCellMemberOverride, getAllRandom }
}

export const useRandomCellMember = () => {
  const dispatch = useAppDispatch()
  const getRandomCellMember = useCallback(
    (filters = {}) => dispatch(requestRandomCellMember({ filters })),
    [dispatch]
  )

  return getRandomCellMember
}

export const useCellingUsers = () => {
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.sealing.userIds || [])

  const getCellingUsers = useCallback(
    (search, params = {}) => dispatch(requestCellMembers({ search, params })),
    [dispatch]
  )

  return { users, getCellingUsers }
}
