import { useCallback, useEffect, useMemo } from 'react'
import { requestFilters, requestSetUsersRole, requestUsers } from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'
import useLocalize from '../../locales/hook'
import { useIonRouter } from '@ionic/react'
import { isLoggedRoutes } from '../../routes/constants/routes'

export const useUsers = () => {
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.users.userIds)
  const totalUsers = useAppSelector((state) => state.users.totalUsers)
  const usersById = useAppSelector((state) => state.users.userById)

  const getUsers = useCallback(
    (search, params, clear) => dispatch(requestUsers({ search, params, clear })),
    [dispatch]
  )

  return { users, totalUsers, getUsers, usersById }
}

export const useUsersFilters = () => {
  const t = useLocalize()
  const dispatch = useAppDispatch()
  const {
    routeInfo: { pathname },
  } = useIonRouter()
  const isLogged = useAppSelector((state) => !!state.auth.token)
  const filters = useAppSelector((state) => state.users.filters)

  const getFilters = useCallback(() => dispatch(requestFilters()), [dispatch])

  const uFilters = useMemo(
    () => ({
      colleges: filters.colleges?.map((item) => ({ ...item, label: t(item.label) })),
      establishments: filters.establishments?.map((item) => ({ ...item, label: t(item.label) })),
    }),
    [filters, t]
  )
  useEffect(() => {
    if (isLogged && isLoggedRoutes()) {
      getFilters()
    }
  }, [isLogged, getFilters])

  return {
    filters: uFilters,
    getFilters,
  }
}

export const useSetUsersRole = () => {
  const dispatch = useAppDispatch()
  const userIds = useAppSelector((state) => state.roles.userIds)

  const setUsersRole = useCallback(
    (role) =>
      dispatch(
        requestSetUsersRole({
          role,
          userIds,
        })
      ),
    [dispatch, userIds]
  )

  return { setUsersRole }
}
