import axios from 'axios'
import { getToken } from '../../redux/auth/utils'
import { deleteAllCookies, setCookie } from '../security/cookies'

const pEnv = window['env']?.REACT_APP_API_URL ? window['env'] : process.env

const checkError = (err: any) => {
  if (
    err?.response?.data?.message === 'error.sessionExpired' ||
    err?.response?.data?.statusCode === 401
  ) {
    deleteAllCookies()

    if (err?.response?.data?.message === 'error.sessionExpired') {
      setCookie('idle', 'active')
    }
    window.location.reload()
  }
}

export const getAxios = async (path: string, headers?: any, showError: boolean = true) => {
  const isFirefox = typeof window['InstallTrigger'] !== 'undefined'

  if (isFirefox) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        try {
          const response = await axios.get(`${pEnv.REACT_APP_API_URL}${path}`, headers)
          resolve(response)
        } catch (err) {
          if (showError) {
            console.error(`failed to get ${path}`, err)
          }
          resolve({ error: err })
        }
      }, 250)
    })
  } else {
    try {
      return await axios.get(`${pEnv.REACT_APP_API_URL}${path}`, headers)
    } catch (err: any) {
      const error = headers?.responseType === 'blob' ? await getBlobRequestError(err) : err
      if (showError) {
        console.error(`failed to get ${path}`, error)
      }
      checkError(error)
      return { error }
    }
  }
}

async function getBlobRequestError({ response }: any) {
  let responseObj = {}
  try {
    const textError = await response.data.text()
    responseObj = JSON.parse(textError)
  } catch (err) {
    console.error(err)
  }
  return responseObj
}

export const setAxios = async (
  path: string,
  body?: any,
  headers?: any,
  showError: boolean = true
) => {
  try {
    return await axios.post(`${pEnv.REACT_APP_API_URL}${path}`, body, headers)
  } catch (err) {
    if (showError) {
      console.error(`failed to post ${path}`, err)
    }
    return { error: err }
  }
}

export const patchAxios = async (
  path: string,
  body?: any,
  headers?: any,
  showError: boolean = true
) => {
  try {
    return await axios.patch(`${pEnv.REACT_APP_API_URL}${path}`, body, headers)
  } catch (err) {
    if (showError) {
      console.error(`failed to patch ${path}`, err)
    }
    checkError(err)
    return { error: err }
  }
}

export const putAxios = async (
  path: string,
  body?: any,
  headers?: any,
  showError: boolean = true
) => {
  try {
    return await axios.put(`${pEnv.REACT_APP_API_URL}${path}`, body, headers)
  } catch (err) {
    if (showError) {
      console.error(`failed to put ${path}`, err)
    }
    checkError(err)
    return { error: err }
  }
}

export const deleteAxios = async (path: string, headers?: any, showError: boolean = true) => {
  try {
    return await axios.delete(`${pEnv.REACT_APP_API_URL}${path}`, headers)
  } catch (err) {
    if (showError) {
      console.error(`failed to delete ${path}`, err)
    }
    checkError(err)
    return { error: err }
  }
}

export const getTokenHeaders = (forceCookie?: boolean) => ({
  headers: {
    Authorization: `Bearer ${getToken(forceCookie)}`,
  },
})
